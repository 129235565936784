import React from 'react';

import { Rule } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import {
  format,
  isBefore,
  startOfMonth,
  lastDayOfMonth,
  eachDayOfInterval,
} from 'date-fns';
import _ from 'lodash';
import {
  endOfMonth,
  endOfYear,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns/esm';
import { Modal, Form, Input } from 'antd';
import { API_URL } from './http';

import { ReactComponent as XSmall } from './icons/XSmall.svg';
import { ReactComponent as XSmallGray } from './icons/xSmall-gray.svg';
import { ReactComponent as Small } from './icons/Small.svg';
import { ReactComponent as SmallGray } from './icons/small-gray.svg';
import { ReactComponent as Medium } from './icons/Medium.svg';
import { ReactComponent as MediumGray } from './icons/meduim-gray.svg';
import { ReactComponent as Large } from './icons/Large.svg';
import { ReactComponent as LargeGray } from './icons/large-gray.svg';
import { ReactComponent as XLarge } from './icons/XLarge.svg';
import { ReactComponent as XLargeGray } from './icons/xlarge-gray.svg';
import { ReactComponent as XXLarge } from './icons/XXLarge.svg';
import { ReactComponent as XXLargeGray } from './icons/xxlarge-gray.svg';
import { ReactComponent as FillXLarge } from './icons/apartments-filled.svg';
import { ReactComponent as FillXXLarge } from './icons/Skyscrapper-Filled.svg';
import { ReactComponent as FillXSmall } from './icons/Home-Filled.svg';
import { ReactComponent as FillSmall } from './icons/medium-filled.svg';
import { ReactComponent as FillMedium } from './icons/Apart-Outlined.svg';
import { ReactComponent as FillLarge } from './icons/apartment-m-filled.svg';
import { SessionData } from 'types/user';
import {
  ClusterStatus,
  InstanceInfo,
  Service,
  Instance,
  ClusterUserRole,
  CSize,
} from 'types/cluster';
import { sum } from 'utils';
import { hideErrorBelowField, resetErrors } from 'utils/fieldsValidation';

const clusterTypeIcons = {
  small: {
    outline: XSmall,
    fill: FillXSmall,
    gray: XSmallGray,
  },
  medium: {
    outline: Small,
    fill: FillSmall,
    gray: SmallGray,
  },
  larges1: {
    outline: Medium,
    fill: FillMedium,
    gray: MediumGray,
  },
  larges2: {
    outline: Large,
    fill: FillLarge,
    gray: LargeGray,
  },
  larges3: {
    outline: XLarge,
    fill: FillXLarge,
    gray: XLargeGray,
  },
  larges4: {
    outline: XXLarge,
    fill: FillXXLarge,
    gray: XXLargeGray,
  },
  large3x: {
    outline: XXLarge,
    fill: FillXXLarge,
    gray: XXLargeGray,
  },
  larges5: {
    outline: XXLarge,
    fill: FillXXLarge,
    gray: XXLargeGray,
  },
  larges6: {
    outline: XXLarge,
    fill: FillXXLarge,
    gray: XXLargeGray,
  },
  larges7: {
    outline: XXLarge,
    fill: FillXXLarge,
    gray: XXLargeGray,
  },
};

export function getClusterTypeIcons(
  type: string,
  shape: 'outline' | 'fill' | 'gray',
) {
  if (type in clusterTypeIcons) {
    return (clusterTypeIcons as Record<string, any>)[type][shape];
  } else {
    return clusterTypeIcons.larges4[shape];
  }
}

// TODO: remove
export const basicClusterTypeName = {
  small: 'XSmall',
  medium: 'Small',
  larges1: 'Medium',
  larges2: 'Large',
  larges3: 'XLarge',
  larges4: '2XLarge',
};
export const extraClusterTypeName = {
  large3x: '3XLarge',
  larges5: '4XLarge',
  larges6: '6XLarge',
  larges7: '10XLarge',
};
export const clusterTypeName = {
  ...basicClusterTypeName,
  ...extraClusterTypeName,
};
type clusterTypeKey = keyof typeof clusterTypeName;

export const sizeToIPU: { [key in clusterTypeKey]: number } = {
  small: 0.5,
  medium: 1,
  larges1: 2,
  larges2: 4,
  larges3: 8,
  larges4: 16,
  large3x: 24,
  larges5: 28,
  larges6: 32,
  larges7: 64,
};

export function getClusterTypeName(type: CSize) {
  return clusterTypeName[type] ?? type;
}

export function getClusterTypeValue(name: string) {
  return Object.keys(clusterTypeName).find(
    key => (clusterTypeName as any)[key] === name,
  );
}

export function getClusterStatus(
  instance: Instance,
  service: Service,
): ClusterStatus {
  const status = service.status;
  const statusInc = instance.status;
  const runningAt = instance.runningAt;

  if (instance.corrupted) {
    return 'corrupted';
  } else if (statusInc === 'stopping') {
    return 'stopping';
  } else if (status === 'loading' && runningAt) {
    return 'starting-up';
  } else if (status === 'loading' && !runningAt) {
    return 'creating';
  } else if (status === 'disconnected') {
    return 'sleeping';
  } else if (status === 'running') {
    return 'running';
  } else {
    return 'undefined';
  }
}

export function canUserCreateCluster({ user, configurations }: SessionData) {
  return (
    user &&
    !user.trialExpired &&
    configurations &&
    configurations.allowedInstances.count > user.instancesCount
  );
}

export function getClusterUrl(
  cloudSSO: string,
  {
    instance,
    service,
    redirect_to,
  }: {
    instance?: InstanceInfo['instance'];
    service?: Service;
    redirect_to?: string;
  },
) {
  let url = '';

  if (redirect_to) {
    url = `${API_URL}/cloudsso?redirect_to=${redirect_to}`;
  }

  if (instance && service) {
    url = `${service.url}`;
  }

  return url;
}

export function splitComaString(pips: string) {
  return pips
    .split(',')
    .map(s => s.trim())
    .filter(Boolean);
}

export function getClusterSize({
  analyticsSizeName,
  analyticsSizeDisplayName,
  loaderSizeName,
  loaderSizeDisplayName,
  analyticsSizeIpu,
  loaderSizeIpu,
}: {
  analyticsSizeName: string;
  analyticsSizeDisplayName: string;
  loaderSizeName: string;
  loaderSizeDisplayName: string;
  analyticsSizeIpu: number;
  loaderSizeIpu: number;
}) {
  if (analyticsSizeName === loaderSizeName) {
    return `${analyticsSizeDisplayName} - ${getCSizeDetails({
      ipu: analyticsSizeIpu,
    })}`;
  } else {
    return `Analytics (${analyticsSizeDisplayName} - ${getCSizeDetails({
      ipu: analyticsSizeIpu,
    })}) Loader (${loaderSizeDisplayName} - ${getCSizeDetails({
      ipu: loaderSizeIpu,
    })})`;
  }
}

export function getCSizeDetails(instance: { ipu: string | number }) {
  return `${instance.ipu} IPU`;
}

export function getCSizeDetailsSummary(instance: {
  ipu: string | number;
  analyticsNodes: number;
  loaderNodes: number;
  csize: string;
  csize2: string;
}) {
  return getAllIU(instance);
}

export function getIU(
  instance: {
    ipu: string | number;
    csize?: string;
    csize2?: string;
    size?: string;
  },
  nodesCount: number,
  service: string,
) {
  const size =
    service === 'analytics'
      ? instance.csize
      : service === 'loader'
      ? instance.csize2
      : instance.size;
  let ipu = 0;

  const defaultIPU = 0.5;
  ipu = sizeToIPU[size as clusterTypeKey] || defaultIPU;

  return `${(ipu / 2) * nodesCount} IPU`;
}

export function getAllIU(instance: {
  ipu: string | number;
  csize?: string;
  csize2?: string;
  size?: string;
  analyticsNodes: number;
  loaderNodes: number;
}) {
  const analyticsCSize = instance.csize;
  const loaderCSize = instance.csize2;
  let analyticsIpu = 0;
  let loaderIpu = 0;

  const defaultIPU = 0.5;
  analyticsIpu = sizeToIPU[analyticsCSize as clusterTypeKey] || defaultIPU;
  loaderIpu = sizeToIPU[loaderCSize as clusterTypeKey] || defaultIPU;

  return `${
    (analyticsIpu / 2) * instance.analyticsNodes +
    (loaderIpu / 2) * instance.loaderNodes
  } IPU `;
}

export const PULL_TIMER_SMALL = 15 * 1000;

export const clusterNameMax = 21;
export const selfManagedClusterNameMax = 32;

export const envNameRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.envNameRequired" />,
  },
  {
    min: 3,
    message: <FormattedMessage id="consentFlow.envNameMinimumCount" />,
  },
  {
    max: 10,
    message: <FormattedMessage id="consentFlow.envNameMaximmumCount" />,
  },
  {
    validator(_rule, value) {
      if (/(^\d).*/.test(value)) {
        return Promise.reject(
          'Name cannot start with a numeric character. Must start with a letter.',
        );
      }
      if (!/^[a-z0-9]*$/.test(value)) {
        return Promise.reject('Only accept lowercase alphanumerics.');
      }

      const blackList = [/(?:create|zookeeper|loader)/i];
      if (blackList.some(reg => reg.test(value))) {
        return Promise.reject(`${value} is not a valid name`);
      } else {
        return Promise.resolve();
      }
    },
  },
];

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const commandRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.subCredRequired" />,
  },
  {
    validator(_rule, value) {
      if (value !== '') {
        if (!isJsonString(value)) {
          return Promise.reject(
            "It looks like this isn't in JSON format. Try copying and pasting the output again",
          );
        }
      }
      return Promise.resolve();
    },
  },
];

export const resourceGroupNameRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.resourceGroupRequired" />,
  },
];

export const resourceRegionSelectionRules: Rule[] = [
  {
    required: true,
    message: (
      <FormattedMessage id="consentFlow.resourceRegionSelectionRequired" />
    ),
  },
];

export const networkNameRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.networkNameRequired" />,
  },
];

export const networkSubnetNameRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.networkSubnetsRequired" />,
  },
];
export const networkSubnetRules: Rule[] = [
  {
    validator(_rule: any, value) {
      let subnetSample = '';
      switch (_rule.field) {
        case 'subnet1':
          subnetSample = '172.16.0.0/14';
          break;
        case 'subnet2':
          subnetSample = '172.20.0.0/14';
          break;
        case 'subnet3':
          subnetSample = '172.24.0.0/24';
          break;
        case 'subnet4':
          subnetSample = '172.24.1.0/24';
          break;
        case 'subnet5':
          subnetSample = '172.24.2.0/24';
          break;
        default:
          break;
      }
      if (
        !value ||
        (value &&
          !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/14|\/24$)/.test(
            value,
          ))
      ) {
        return Promise.reject(
          `Please enter a valid subnet. Ex: ${subnetSample}`,
        );
      }
      return Promise.resolve();
    },
  },
];

export const subIDRules: Rule[] = [
  {
    required: true,
    message: <FormattedMessage id="consentFlow.subIDRequired" />,
  },
  {
    validator(_rule, value) {
      if (
        value &&
        !/^([a-zA-Z0-9]){8}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){12}/.test(
          value,
        )
      ) {
        return Promise.reject(
          `Please enter a valid subscription ID. It must match the format 00000000-0000-0000-0000-000000000000 and it only accepts alphanumeric characters.`,
        );
      } else {
        return Promise.resolve();
      }
    },
  },
];

export const commonClusterNameRules: Rule[] = [
  {
    min: 3,
  },
  {
    validator(_rule, value) {
      if (value !== '') {
        if (/^-.*|.*-$/.test(value)) {
          return Promise.reject('Dash not allowed in beginning or end');
        }
        if (/--+/.test(value)) {
          return Promise.reject(
            'Should not contain multiple consequent dashes',
          );
        }
        if (/\s/.test(value)) {
          return Promise.reject('Spaces not allowed');
        }
        if (!/^(?!^[0-9].*$).*^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(value)) {
          return Promise.reject('Name is invalid check naming hint below');
        }
      }
      return Promise.resolve();
    },
  },
  {
    validator(_rule, value) {
      const blackList = [/(?:create|zookeeper|loader)/i];
      if (blackList.some(reg => reg.test(value))) {
        return Promise.reject(`${value} is not a valid name`);
      } else {
        return Promise.resolve();
      }
    },
  },
];

export const clusterNameRules: Rule[] = [
  ...commonClusterNameRules,
  {
    required: true,
    message: <FormattedMessage id="clusterForm.clusterNameRequired" />,
  },
  {
    max: clusterNameMax,
  },
];

export const selfManagedClusterNameRules: Rule[] = [
  ...commonClusterNameRules,
  {
    required: true,
    message: (
      <FormattedMessage id="createNewSubClusterModal.selfManagedClusterNameRequired" />
    ),
  },
];

export function getDocsLink(clusterImage: string) {
  let release = parseFloat(clusterImage);

  if (isNaN(release) || !release) {
    return;
  }

  return `https://docs.incorta.com/${release.toFixed(1)}`;
}

export function getConsumptionTableDate(consumptionAgg: any, month: string) {
  let monthDays = getDaysOfMonth(month);
  return monthDays.map(day => {
    let dayFormat = format(day, 'yyyy-MM-dd');

    let incorta =
      consumptionAgg.incorta.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let spark =
      consumptionAgg.spark.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let total =
      consumptionAgg.total.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let hours = Array.from({ length: 24 }, (_, i) => {
      let hour = i + 1;

      let incorta =
        consumptionAgg.incorta.hourly[dayFormat]?.find(
          (usage: any) => usage.hour === i,
        )?.powerUnit ?? 0;

      let spark =
        consumptionAgg.spark.hourly[dayFormat]?.find(
          (usage: any) => usage.hour === i,
        )?.powerUnit ?? 0;

      return {
        hour,
        incorta,
        spark,
      };
    });

    let result = {
      day,
      dayFormat: format(day, 'MMM d'),
      incorta,
      spark,
      total,
      isBeforeNow: isBefore(day, new Date()),
      hours,
    };

    return result;
  });
}

export type TimeRange =
  | { type: 'month' }
  | { type: 'last-month' }
  | { type: 'year' }
  | { type: 'last-year' }
  | { type: 'custom'; from: Date; to: Date };

export function getTimeRangeDates(timeRange: TimeRange) {
  switch (timeRange.type) {
    case 'month': {
      return {
        start: startOfMonth(new Date()),
        end: new Date(),
      };
    }
    case 'last-month': {
      let lastMonth = subMonths(new Date(), 1);
      return {
        start: startOfMonth(lastMonth),
        end: endOfMonth(lastMonth),
      };
    }
    case 'year': {
      return {
        start: startOfYear(new Date()),
        end: new Date(),
      };
    }
    case 'last-year': {
      let lastYear = subYears(new Date(), 1);
      return {
        start: startOfYear(lastYear),
        end: endOfYear(lastYear),
      };
    }
    case 'custom': {
      return {
        start: timeRange.from,
        end: timeRange.to,
      };
    }
  }
}

export function getClustersConsumption(
  clusters: {
    name: string;
    id: string;
    consumptionAgg: any;
  }[],
  { start, end }: { start: Date; end: Date },
) {
  return clusters.map(cluster => {
    let consumptionDays = getConsumptionTableDateTODO(cluster.consumptionAgg, {
      start,
      end,
    });

    let consumptionMonths = _.groupBy(consumptionDays, 'monthFormat');

    let consumption = Object.entries(consumptionMonths).map(([, days]) => {
      let [firstDay] = days;

      return {
        month: firstDay.day,
        monthFormat: firstDay.monthFormat,
        incorta: sum(days, day => day.incorta),
        spark: sum(days, day => day.spark),
        total: sum(days, day => day.total),
        days,
      };
    });

    return {
      id: cluster.id,
      name: cluster.name,
      consumption,
    };
  });
}

function getConsumptionTableDateTODO(
  consumptionAgg: any,
  { start, end }: { start: Date; end: Date },
) {
  let days = eachDayOfInterval({ start, end });
  return days.map(day => {
    let dayFormat = format(day, 'yyyy-MM-dd');

    let incorta =
      consumptionAgg.incorta.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let spark =
      consumptionAgg.spark.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let total =
      consumptionAgg.total.daily.find((day: any) => day.date === dayFormat)
        ?.powerUnit ?? 0;

    let hours = Array.from({ length: 24 }, (_, i) => {
      let hour = i + 1;

      let incorta =
        consumptionAgg.incorta.hourly[dayFormat]?.find(
          (usage: any) => usage.hour === i,
        )?.powerUnit ?? 0;

      let spark =
        consumptionAgg.spark.hourly[dayFormat]?.find(
          (usage: any) => usage.hour === i,
        )?.powerUnit ?? 0;

      return {
        hour,
        incorta,
        spark,
      };
    });

    let result = {
      day,
      monthFormat: format(day, 'MMM yyyy'),
      dayFormat: format(day, 'd'),
      incorta,
      spark,
      total,
      isBeforeNow: isBefore(day, new Date()),
      hours,
    };

    return result;
  });
}

function getDaysOfMonth(month: string) {
  let start = startOfMonth(new Date(month));
  let end = lastDayOfMonth(new Date(month));
  return eachDayOfInterval({ start, end });
}

export function getMonthRange(month: string) {
  let startDate = format(startOfMonth(new Date(month)), 'yyyy-MM-dd');
  let endDate = format(lastDayOfMonth(new Date(month)), 'yyyy-MM-dd');
  return [startDate, endDate];
}

export function chunkCluster(clusters?: InstanceInfo[]) {
  const result: {
    connectedClusters: InstanceInfo[];
    disconnectedClusters: InstanceInfo[];
  } = {
    connectedClusters: [],
    disconnectedClusters: [],
  };

  if (clusters) {
    for (let cluster of clusters) {
      const { instance, services } = cluster;
      const status = getClusterStatus(instance, services[0]);

      if (status === 'sleeping') {
        result.disconnectedClusters.push(cluster);
      } else {
        result.connectedClusters.push(cluster);
      }
    }
  }

  return result;
}

export function getDateRangeString({ start, end }: { start: Date; end: Date }) {
  let startDay = format(start, 'd');
  let startMonth = format(start, 'MMMM');
  let startYear = format(start, 'yyyy');
  let endDay = format(end, 'd');
  let endMonth = format(end, 'MMMM');
  let endYear = format(end, 'yyyy');

  let prefix = '';
  let startDate = startDay;
  let endDate = endDay;
  let suffix = '';

  if (startYear === endYear && startMonth === endMonth) {
    prefix = `${startMonth} `;
  } else {
    startDate = `${startMonth} ${startDate}`;
    endDate = `${endMonth} ${endDate}`;
  }

  if (startYear === endYear) {
    suffix = `, ${startYear}`;
  } else {
    startDate = `${startDate}, ${startYear}`;
    endDate = `${endDate}, ${endYear}`;
  }

  return `${prefix}${startDate} - ${endDate}${suffix}`;
}

// https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side/24922761#24922761
function exportToCsv(filename: string, rows: any[]) {
  var processRow = function (row: any) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadConsumptionToCSV(
  filteredClusters: ReturnType<typeof getClustersConsumption>,
) {
  let rows = [
    [
      'Clusters',
      'Date',
      'Incorta Used Units',
      'Spark Used Units',
      'Total Used Units',
    ],
    ...filteredClusters.flatMap(cluster => {
      return cluster.consumption.flatMap(consumption => {
        return consumption.days.map(day => {
          return [
            cluster.name,
            format(day.day, 'yyyy-MM-dd'),
            day.incorta,
            day.spark,
            day.total,
          ];
        });
      });
    }),
  ];

  exportToCsv('consumption.csv', rows);
}

export function mapRuleToName(rule: ClusterUserRole) {
  switch (rule) {
    case 'owner':
      return 'Owner';
    case 'developer':
      return 'User';
    case 'accountManager':
      return 'Billing User';
    case 'accountAdmin':
      return 'Manager';
  }
}

export function deleteModalConfirm({
  clusterName,
  handleDelete,
  clusterNameValidation = null,
  clusterNameError = null,
}: {
  clusterName: string;
  handleDelete: Function;
  clusterNameValidation?: any;
  clusterNameError?: any;
}) {
  let name: string;
  Modal.confirm({
    title: `Are you sure you want to delete ${clusterName} cluster?`,
    content: (
      <Form layout="vertical">
        <p>
          This action cannot be undone. You will not be able to restore{' '}
          {clusterName} cluster if you delete it. Please confirm by typing the
          cluster name.
        </p>
        <div className="input-wrapper input-field-container">
          <Form.Item
            name="cluster-name"
            label="Type Cluster Name"
            style={{ marginBottom: 0 }}
          >
            <Input
              placeholder={clusterName}
              onChange={e => (name = e.target.value)}
              onKeyUp={e => {
                if (e.code !== 'Enter') {
                  hideErrorBelowField('cluster-name');
                  clusterNameValidation(e, clusterName);
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    ),
    okText: 'Delete',
    cancelText: 'Cancel',
    centered: true,
    width: 620,
    async onOk() {
      resetErrors();
      clusterNameValidation.cancel();

      if (clusterName === name) {
        return handleDelete(clusterName).catch((error: string) => {
          clusterNameError(name, clusterName);
        });
      }
      clusterNameError(name, clusterName);
      throw Error();
    },
  });
}

export function getSizeValueFromSliderValue(
  siderValues: number[],
  siderValue: number,
) {
  let sliderValue = 0;
  let min = Infinity;

  for (let n of siderValues) {
    if (Math.abs(siderValue - n) < min) {
      min = Math.abs(siderValue - n);
      sliderValue = n;
    }
  }

  return sliderValue;
}
